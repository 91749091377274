.app_construction-msg-container {
  margin-left: 10%;
  margin-top: 5%;
  width: 80%;
  height: 80%;
  border-radius: 32px;
  background: #ff005d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: 58px;
}

.app_construction {
  margin-top: 100px;
  width: 300px;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./assets/construction.png');
}
