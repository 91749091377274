.selfie {
  width: 200px;
  height: 200px;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  background-repeat: no-repeat;
}

.selfie_name {
  text-align: center;
  white-space: pre;
  line-height: 1;
}

.selfie_name-optional {
  opacity: 0.5;
}

.selfie_name-dummy {
  margin-top: -60px;
}

.selfie_name-dummy-spacing {
  letter-spacing: 0;
}

.animate-selfie {
  animation: FaceFloat 10s infinite;
}

@keyframes FaceFloat {
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(5px);
  }
  75% {
    transform: translateY(-2.5px);
  }
}

.app_local-game-loading {
  font-weight: bold;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 64px;
  animation: app_local-game-loading 2s forwards;
  position: absolute;
}

@keyframes app_local-game-loading {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1.1);
  }
  20%,
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}

.app_local-game-animation {
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: app_local-game-animation-enter 1s 2s forwards;
}

@keyframes app_local-game-animation-enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app_game-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
}

.app_tv-close-container {
  animation: shutdown 1.5s forwards;
  background-color: black;
}

.app_tv-open-container {
  animation: turn-on 1.5s reverse;
  background-color: black;
}

.app_iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@keyframes shutdown {
  70% {
    transform: scale(1, 0.02);
    opacity: 1;
  }
  75%,
  100% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
}

@keyframes turn-on {
  70% {
    transform: scale(1, 0.02);
    opacity: 1;
  }
  75%,
  100% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
}

.app-pin_code {
  background-image: url('./assets/pinCode.webp');
  background-size: contain;
  width: 70px;
  height: 70px;
}

.app-pin_code_lg {
  background-image: url('./assets/pinCode.webp');
  background-size: contain;
  width: 200px;
  height: 200px;
}
