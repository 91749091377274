.navigation-root {
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.navigation-slowdowns {
  background: red;
  width: 100%;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  animation: navigation-slowdowns-disappear 30s forwards;
}

@keyframes navigation-slowdowns-disappear {
  0% {
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.navigation-content {
  transition: 0.3s;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-left: 60px;
}

.navigation-rows {
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  z-index: 1;
  transition: 0.3s transform;
  position: absolute;
  top: 55%;
}

.navigation-menu {
  flex: 1;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 37px;
  z-index: 1000;
  transition: 0.3s;
}

.navigation-menu_active {
  background-color: rgba(255, 255, 255, 0.15);
}

.navigation-menu_yard {
  margin-top: 10%;
  height: 90%;
  width: 90%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigation-menu_yard_title {
  margin-top: 20px;
  font-size: 32px;
  margin-bottom: 10px;
}

.navigation-menu_yard_body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
}

.navigation-menu_yard_player {
  margin-bottom: 20px;
}

.navigation-invite_friends {
  width: 270px;
  height: 60px;
  background-color: white;
  border-radius: 7px;
  margin-bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  border: 1px solid white;
  box-shadow: unset;
}

.navigation-invite_friends_active {
  box-shadow: rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px,
    rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
  border: 6px solid rgba(240, 46, 170, 0.8);
}

.navigation-invite_friends_content {
  background-image: url('../assets/addFriend.webp');
  width: 42px;
  height: 42px;
  background-size: contain;
}
