.comm_container-root {
  z-index: 1;
  position: relative;
}

.comm_container-reaction_root {
  position: absolute;
  bottom: 0;
}

.comm_container-reaction_avatar {
  height: 100px;
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  animation: comm_container-reaction_avatar_enter 1s forwards;
  background-color: white;
  border-radius: 50%;
}

@keyframes comm_container-reaction_avatar_enter {
  0% {
    transform: translateY(300px);
  }
  25% {
    transform: translateY(40px);
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(300px);
  }
}

.comm_container-reaction {
  font-size: 70px;
  animation: comm_container-reaction_enter 1s;
  opacity: 0;
}

@keyframes comm_container-reaction_enter {
  0% {
    opacity: 1;
    transform: translate(20px, -20px);
  }
  50% {
    opacity: 1;
    transform: translate(120px, -120px);
  }
  100% {
    opacity: 0;
    transform: translate(120px, -120px) rotate(30deg);
  }
}
