@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  outline: transparent none 0;
}

body {
  outline: transparent none 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 36px;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 1) 100%) !important;
}
body * {
  outline: transparent none 0;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fill-height {
  height: 100%;
}

.text--subtitle {
  font-size: 64pt;
  line-height: 81pt;
  font-weight: normal;
}

.text--md {
  font-size: 48pt;
  line-height: 60pt;
  font-weight: normal;
}
.text--sm {
  font-size: 22pt;
  line-height: 30pt;
  font-weight: normal;
}

.text-uppercase {
  text-transform: uppercase;
}
.font-weight-bold {
  font-weight: bold;
}

.mt-5 {
  margin-top: 20px !important;
}

.px-0 {
  padding-left: 2px;
  padding-right: 2px;
}

.px-1 {
  padding-left: 4px;
  padding-right: 4px;
}

.mx-0 {
  margin-right: 2px;
  margin-left: 2px;
}

.mx-1 {
  margin-left: 2px;
  margin-right: 2px;
}
.mx-2 {
  margin-left: 10px;
  margin-right: 10px;
}
.mx-3 {
  margin-left: 18px;
  margin-right: 18px;
}

.font-family-monospaced,
.font-family-monospaced * {
  font-family: Courier New, monospace !important;
}

.code-digit {
  border-bottom: 6px dotted;
  min-width: 120px;
}

.flex {
  flex: 1;
}
.d-flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}

.d-inline-block {
  display: inline-block;
}

.w-100 {
  width: 100%;
}
.justify-center {
  justify-content: center !important;
}
.justify-space-around {
  justify-content: space-around;
}
.align-center {
  align-items: center;
}

.text--white {
  color: white;
}
.text--orange {
  color: orange;
}
.text--grey {
  color: grey;
}
.text--green {
  color: #00a700;
}
.text--black {
  color: #000000;
}
.text-sm {
  font-size: 0.8em;
}
.text-xs {
  font-size: 0.5em;
  line-height: 0.8em;
}
.text-xl {
  font-size: 2em;
}
.muted {
  opacity: 0.7;
}
.text-h3 {
  font-size: 1.6em;
  line-height: 0.9em;
}
.text-h4 {
  font-size: 1.3em;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-underline {
  text-decoration: underline;
}
.overflow-auto {
  overflow: auto;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
  z-index: 1;
}

.avatar {
  overflow: hidden;
  max-width: 100px;
  padding: 0 15px;
}

.avatar.avatar--xl {
  max-width: 220px;
}
.avatar.avatar--xxl {
  max-width: 300px;
}

.avatar img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  border: 3px solid;
}

.leader .avatar img {
  border-color: orange;
}

.activePlayer {
  color: #0000ad;
}
table {
  font-weight: 300;
  min-width: 1000px;
}

.bg-shadow {
  background: rgba(0, 0, 0, 0.6);
}
.bg-shadow--light {
  background: rgba(255, 255, 255, 0.6);
}

.transition--fadeIn {
  opacity: 0;
  transition: opacity 0.3s;
}
.transition--fadeIn.transition--active {
  opacity: 1;
}

@keyframes scroll {
  0% {
    margin-top: 300px;
  }
  100% {
    margin-top: -1300px;
  }
}
.animation--scroll {
  overflow: hidden;
}
.animation--scroll > div:first-child {
  animation: scroll 24s infinite;
}

@keyframes shrink-and-fade-in {
  0% {
    opacity: 0;
    transform: scale(3);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.shrink-and-fade-in {
  opacity: 0;
  animation: shrink-and-fade-in 0.5s forwards;
}
