.navigation_row-root {
}

.navigation_row-title {
  color: white;
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 700;
  margin-top: 22px;
}
.navigation_row-content {
  display: flex;
  flex-direction: row;
}
