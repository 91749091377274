.navigation_item-root {
  margin-right: 22px;
  display: flex;
  flex-direction: column;
}

.navigation_item-item {
  width: 300px;
  height: 225px;
  box-sizing: border-box;
  border-radius: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  border: 4px solid transparent;
  background-position: center;
}

.navigation_item-item_focused {
  border: 4px solid #ff0071;
}
